import XHtmlArea from 'DesignComponents/Molecules/XHtmlArea/XHtmlArea';
import { API_ROOT_PATH } from 'Shared/Constants/route';

import { styled } from 'Theme/stitches.config';

export const GET_TERMSANDCONDITIONS_URL =
  API_ROOT_PATH + '/checkout/GetTermsAndConditions';

type Props = {
  text?: string;
};

const TermsAndConditionsModalContainer = ({ text }: Props) => {
  return (
    <ConfirmationWrapper>
      <XHtmlArea content={text || ''} />
    </ConfirmationWrapper>
  );
};

const ConfirmationWrapper = styled('div', {
  mt: 6,
  lineHeight: '$lh24',
});

export default TermsAndConditionsModalContainer;
